import React from "react";
import { Link } from 'react-router-dom'
import { useNavigate } from "react-router-dom";
import { Apis_Hit } from "../apis/apis";

export default function NavBar() {

    const userData = JSON.parse(localStorage.getItem("user"));
    const token = JSON.parse(localStorage.getItem("token"));
    const URLPathName = window.location.pathname
    const navigate = useNavigate();

    const loginOut = async () => {

        // await Apis_Hit.loginOut_API()
        // .then((resp) => {
        //     console.log(resp.data.message)
        // })
        // .catch((errors) => {
        //     console.log(errors)
        // });

        localStorage.removeItem('token');
        localStorage.removeItem('user');
        navigate("/", { replace: true })

    };

    if (token) {
        return (
            <>
                <div className="bg-light pt-3 mb-5 border-bottom border-secondary">
                    <div className="container">
                        <div className="row">
                            {userData.role !== "Super Admin" && <div className="col-1" style={{ color: (URLPathName == "/EmployeeData" && "green") }}>
                                <Link className="nav-link" to={"/EmployeeData"}>Self Data</Link>
                            </div>}
                            <div className="col" style={{ color: (URLPathName == "/EmployeeDataAdmin" && "green") }}>
                                {
                                    ((userData.role === 'Super Admin') || (userData.role === 'Manager') || (userData.role === 'HR'))
                                    &&
                                    <Link className="nav-link" to={"/EmployeeDataAdmin"}>Get Employee Data</Link>
                                }

                            </div>
                            <div className="col" style={{ color: (URLPathName == "/AllUsersDataAdmin" && "green") }}>
                                {
                                    ((userData.role === 'Super Admin') || (userData.role === 'Manager') || (userData.role === 'HR'))
                                    &&
                                    <Link className="nav-link" to={"/AllUsersDataAdmin"}>Get All Users Data</Link>
                                }

                            </div>
                            <div className="col" style={{ color: (URLPathName == "/UpdateUsers" && "green") }}>
                                {
                                    ((userData.role === 'Super Admin') || (userData.role === 'Manager') || (userData.role === 'HR'))
                                    &&
                                    <Link className="nav-link" to={"/UpdateUsers"}>Update Users</Link>

                                }

                            </div>


                            <div className="offset-1 col">
                                <p className="nav-link text-primary">{userData?.name} </p>
                            </div>

                            <div className="col">
                                <p onClick={() => loginOut()}><Link className="nav-link text-secondary">Logout</Link></p>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}