import React, { useEffect, useState } from "react";
import { Apis_Hit } from "../apis/apis";
import { DateRangePicker } from "react-date-range";
import { format } from "date-fns";

import moment from "moment";

import Table from "../components/table";
import Loading from "../components/loading_builtinSoft";
import * as sweetAlert from 'sweetalert';

export default function EmployeeData() {
  const [employeeData, setEmployeeData] = useState([]);
  const [employeeDataHours, setEmployeeDataHours] = useState([]);
  const [body, setBody] = useState({});
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState([{ key: "selection" }]);
  const [contentDisplay, setContentDisplay] = useState(false);


  const getDate = async () => {
    if (!date[0].startDate || !date[0].endDate) {
      body.start_date = moment().startOf('month').format('YYYY-MM-DD 00:00:00');
      body.end_date = moment().endOf('month').format('YYYY-MM-DD 23:59:59');
    } else {
      body.start_date = format(date[0].startDate, "yyyy-MM-dd 00:00:00");
      body.end_date = format(date[0].endDate, "yyyy-MM-dd 23:59:59");

    }
    getUserData();

  };

  // Get All Categories

  const getUserData = async () => {
    setLoading(true);
    await Apis_Hit.employeeData_API(body)
      .then((resp) => {
        setEmployeeDataHours(resp.data.response.sum_hours);
        setEmployeeData(resp.data.response.data);
        setLoading(false);
        setContentDisplay(true);
      })
      .catch((errors) => {
        setEmployeeDataHours("No Data Found");
        setLoading(true);
        console.log(errors)
      });
  };

  const dataHeadings = [
    { key: "date", name: "Date" },
    { key: "day", name: "Day" },
    { key: "check_in", name: "Check in" },
    { key: "break_in", name: "Break Start" },
    { key: "break_out", name: "Break End" },
    { key: "check_out", name: "Check out" },
    { key: "working_hours", name: "Working Hours" },
    { key: "break_hours", name: "Break Hours" },
    { key: "total_hours", name: "Total Hrs / Day" },
  ];

  return (
    <div className="container">
      <div className="row mt-3">
        <div className="offset-1 col">
          <DateRangePicker
            onChange={(item) => setDate([item.selection])}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            months={1}
            ranges={date}
            direction="horizontal"
          />
        </div>
        <div className="offset-1 col">
          <button
            className="btn btn-primary"
            style={{ marginTop: "82px" }}
            onClick={() => getDate()}
          >
            Submit
          </button>
        </div>
      </div>

      {loading ? (
        <Loading />
      ) : (
        <>
          <div className="py-5" style={{ display: (contentDisplay ? "" : "none") }}>
            <div className="row">
              <div className="col-2">
                <h1>Data</h1>
              </div>
              <div className="offset-5 col-2 pt-3">
                <h4>Total Hours : </h4>
              </div>
              <div className="col-2 pt-3">
                <h4 className="text-danger">{employeeDataHours}</h4>
              </div>
            </div>
            <Table headings={dataHeadings} bodyData={employeeData} />
          </div>
        </>
      )}
    </div>
  );
}
