import React, { useState } from "react";
import { Apis_Hit } from "../../apis/apis";
import { useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import * as sweetAlert from "sweetalert";
import Loading from "../../components/loading_builtinSoft";

const ResetPassword = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const [password, setPassword] = useState();
  const token = params.get("token");
  const email = params.get("email");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [passwordError, setPasswordErr] = useState("");
  const [matchPass, setMatchPass] = useState();

  const resetPassword = async () => {
    if (token && email && password?.new && password?.confirm && !passwordError) {
      if (password?.new === password?.confirm) {
        setLoading(true);
        await Apis_Hit.resetPassword_API({
          token: token,
          email: email,
          password: password?.new,
          password_confirmation: password?.confirm,
        })
          .then((resp) => {
            if (resp?.data?.status) {
              sweetAlert("Success!", resp?.data?.message, "success");
              navigate("/", { replace: true });
              setLoading(false);
            } else {
              sweetAlert("Failed", resp?.data?.message, "error");
              setLoading(false);
            }
          })
          .catch((errors) => {
            sweetAlert("Failed", errors?.response?.data?.message, "error");
            setLoading(false);
          });
      } else {
        sweetAlert("Error", "Password not match", "error");
      }
    } else if (!password?.new) {
      sweetAlert("Error", "Please enter password", "error");
    } else if (!password?.confirm) {
      sweetAlert("Error", "Please Confirm Password");
    } else if (!token && !email) {
      sweetAlert("Error", "Something wrong", "error");
    }
  };
  const handleValidation = (evnt) => {
    const passwordInputValue = evnt.target.value;
    const passwordInputFieldName = evnt.target.name;
    //for password
    if (passwordInputFieldName === "password") {
      const uppercaseRegExp = /(?=.*?[A-Z])/;
      const lowercaseRegExp = /(?=.*?[a-z])/;
      const digitsRegExp = /(?=.*?[0-9])/;
      const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
      const minLengthRegExp = /.{8,}/;
      const passwordLength = passwordInputValue.length;
      const uppercasePassword = uppercaseRegExp.test(passwordInputValue);
      const lowercasePassword = lowercaseRegExp.test(passwordInputValue);
      const digitsPassword = digitsRegExp.test(passwordInputValue);
      const specialCharPassword = specialCharRegExp.test(passwordInputValue);
      const minLengthPassword = minLengthRegExp.test(passwordInputValue);
      let errMsg = "";
      if (passwordLength === 0) {
        errMsg = "Password is empty";
      } else if (!uppercasePassword) {
        errMsg = "At least one Uppercase";
      } else if (!lowercasePassword) {
        errMsg = "At least one Lowercase";
      } else if (!digitsPassword) {
        errMsg = "At least one digit";
      } else if (!specialCharPassword) {
        errMsg = "At least one Special Characters";
      } else if (!minLengthPassword) {
        errMsg = "At least minumum 8 characters";
      } else {
        errMsg = "";
      }
      setPasswordErr(errMsg);
    }
  };
  const matchPassword = (event) => {
    const matched = password?.new === event.target.value;
    if (!password?.confirm) {
      setMatchPass();
    } else if (matched) {
      setMatchPass("Password matched");
    } else if (!matched) {
      setMatchPass("Password doesn't matched");
    }
  };
  return (
    <section className="vh-100">
      <div className="container-fluid h-custom">
        <div className="row d-flex justify-content-center align-items-center h-100">
          <div className="col-md-8 col-lg-5 col-xl-4">
            {loading ? (
              <Loading />
            ) : (
              <img
                src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/draw2.webp"
                className="img-fluid"
                alt=""
              />
            )}
          </div>
          <div className="col-md-8 col-lg-6 col-xl-4 offset-xl-1">
            <form>
              <div className="form-outline mb-4">
                <label className="form-label" htmlFor="form3Example3">
                  New Password
                </label>
                <input
                  type="email"
                  name="password"
                  id="form3Example3"
                  className="form-control form-control-lg"
                  placeholder="Enter new Password"
                  onChange={(e) => {
                    setPassword({ ...password, new: e.target.value });
                  }}
                  onKeyUp={(e) => handleValidation(e)}
                />
                {
                  passwordError &&
                  <div className="errormsg">{passwordError}</div>
                }
              </div>
              <div className="form-outline mb-4">
                <label className="form-label" htmlFor="form3Example3">
                  Confirm Password
                </label>
                <input
                  type="email"
                  id="form3Example3"
                  className="form-control form-control-lg"
                  placeholder="Confirm your password"
                  onKeyUp={(e) => matchPassword(e)}
                  onChange={(e) => {
                    setPassword({ ...password, confirm: e.target.value });
                  }}
                />
                {
                  matchPass &&
                  <div className="errormsg">{matchPass}</div>
                }

              </div>

              <div className="text-center text-lg-start mt-4 pt-2">
                <button
                  type="button"
                  className="btn btn-primary btn-lg "
                  style={{ marginLeft: "25%", width: "50%" }}
                  onClick={() => resetPassword()}
                >
                  Set Password
                </button>
              </div>
              <div className="d-flex justify-content-between align-items-center mt-5">
                <div className="form-check mb-0"></div>
                <Link className="nav-link text-primary text-body" to={"/"}>
                  <u>Back to Login</u>
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ResetPassword;
