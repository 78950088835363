import React, { Component, useState } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

function CK_EDITOR({setCK_EDITOR_data, comment}) {
    // class CK_EDITOR extends Component {
    // render() {
    //   const [CK_EDITOR_data, setCK_EDITOR_data] = useState([]);

    return (
        <div className="App" style={{overflowY: "scroll", maxHeight:"300px"}}>
            {/* <h2>Using CKEditor 5 build in React</h2> */}
            <CKEditor
                editor={ClassicEditor}
                data={comment}
                onReady={editor => {
                    setCK_EDITOR_data(editor.getData());
                    // You can store the "editor" and use when it is needed.
                    // console.log('Editor is ready to use!', editor);
                }}
                onChange={(event, editor) => {
                    setCK_EDITOR_data(editor.getData());
                    // const data = editor.getData();
                    // console.log({ event, editor, data });
                }}
                onBlur={(event, editor) => {
                    setCK_EDITOR_data(editor.getData());
                    // console.log('Blur.', editor);
                    // console.log(CK_EDITOR_data);
                }}
                onFocus={(event, editor) => {
                    setCK_EDITOR_data(editor.getData());
                    // console.log('Focus.', editor);
                }}
            />
        </div>
    );
    // }
}

// export default CK_EDITOR;
export default CK_EDITOR ;