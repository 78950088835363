import { Navigate } from "react-router-dom";

export const TokenCheck = ({ children }) => {

  const token = JSON.parse(localStorage.getItem("token"))

  if (!token) {
    return <Navigate to="/" replace />
  }

  return children;
}