import React, { useEffect, useState } from "react";
import { Apis_Hit } from "../../apis/apis";
import * as sweetAlert from "sweetalert";
import Loading from "../../components/loading_builtinSoft";

export default function UpdateUsers() {
  const [body, setBody] = useState({});
  const [allUsersData, setAllUsersData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [contentDisplay, setContentDisplay] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    name: "",
    status: 0,
  });

  const getAllUsers = async () => {
    await Apis_Hit.getAllUsers_API()
      .then((resp) => {
        setAllUsersData(resp.data.response.data);
      })
      .catch((errors) => {
        sweetAlert("Error!", "Data Not Found.", "error");
        console.log(errors);
      });
  };

  useEffect(() => {
    getAllUsers();
  }, []);

  const getUsersData = async () => {
    setLoading(true);

    if (!body.user_id) {
      sweetAlert("**USER** Not Selected!", "Please Provide Data.", "warning");
      setLoading(false);
    } else {
      await Apis_Hit.getUsersData_API(body)
        .then((resp) => {
          getAllUsers();
          const data = resp?.data?.response?.data;
          setUserData(data);
          setFormData({
            email: data?.email,
            name: data?.name,
            status: data?.status,
            user_id: data?.user_id,
          });
          setLoading(false);
          setContentDisplay(true);
        })
        .catch((errors) => {
          console.log(errors);
        });
    }
  };

  const setUsersData = async () => {
    await Apis_Hit.setUsersData_API(formData, formData.user_id)
      .then((resp) => {
        sweetAlert("Success!", "All Done.", "success");
        getUsersData();
        getAllUsers();
      })
      .catch((errors) => {
        console.log(errors);
        sweetAlert("Error!", "Unsuccessful.", "error");
      });
  };

  const getLatestAttendance = async () => {
    setLoading(true);
    setBody({});
    body.manualRequest = true;
    await Apis_Hit.getLatestAttendance_API(body)
      .then((resp) => {
        // setLoading(false);
        if (resp.data.status == true) {
          sweetAlert("Updated", "Attendance Updated.", "success");
        } else {
          sweetAlert("Error!", "Request Unsuccessful.", "error");
        }
      })
      .catch((errors) => {
        sweetAlert("Error!", "Unsuccessful.", "error");
        console.log(errors);
      });
    setBody({});
    setLoading(false);
  };

  const getLatestUsers = async () => {
    setLoading(true);
    await Apis_Hit.getLatestUsers_API()
      .then((resp) => {
        if (resp.data.status == true) {
          sweetAlert("Updated", "Users Updated.", "success");
        } else {
          sweetAlert("Error!", "Request Unsuccessful.", "error");
        }
      })
      .catch((errors) => {
        sweetAlert("Error!", "Unsuccessful.", "error");
        console.log(errors);
      });
    setLoading(false);
  };

  return (
    <div className="container blurredElement">
      <h5 className="mb-3">Get User Data</h5>
      <div>
        <select
          defaultValue={"default"}
          className={"btn btn-light border border-dark"}
          onChange={(e) => {
            body.user_id = e.target.value;
            getUsersData();
          }}
          style={{ textAlign: "left" }}
        >
          <option value={"default"} disabled>
            Please Select Value
          </option>
          {allUsersData?.map((item, index) => (
            <option key={index} value={item.user_id}>
              {`${item.name} ${item?.status ? ' - Active' : ' - Inactive'}`}
            </option>
          ))}
        </select>
        {/* <button className="btn btn-primary ms-3" onClick={() => getUsersData()}>
          Submit
        </button> */}

        <div className="btn-group" style={{ float: "right" }}>
          <button
            className="btn btn-secondary"
            onClick={() => getLatestUsers()}
          >
            {" "}
            Update Users{" "}
          </button>
          <button
            className="btn btn-success"
            onClick={() => getLatestAttendance()}
          >
            {" "}
            Update Attendance{" "}
          </button>
        </div>
      </div>

      {loading ? (
        <>
          <Loading />
        </>
      ) : (
        <div className="mt-5" style={{ display: contentDisplay ? "" : "none" }}>
          <div className="row">
            <div className="col-4">
              <p>Name</p>
            </div>

            <div
              className="col-6"
              style={{ color: userData.name == null ? "red" : "blue" }}
            >
              <p>{userData.name == null ? "N/A" : userData.name}</p>
            </div>
          </div>
          <div className="row">
            <div className="col-7">
              <input
                type="text"
                className="form-control"
                value={formData.name}
                aria-describedby="text"
                onChange={(e) =>
                  setFormData({ ...formData, name: e.target.value })
                }
                required
              />
            </div>
          </div>
          <div className="row pt-3">
            <div className="col-4">
              <p>Email address</p>
            </div>

            <div
              className="col-6"
              style={{ color: userData.email == null ? "red" : "blue" }}
            >
              <p>{userData.email == null ? "N/A" : userData.email}</p>
            </div>
          </div>

          <div className="row">
            <div className="col-7">
              <input
                type="email"
                value={formData.email}
                className="form-control"
                aria-describedby="emailHelp"
                onChange={(e) =>
                  setFormData({ ...formData, email: e.target.value })
                }
                required
              />
            </div>
          </div>

          <div className="row pt-3">
            <div className="col-4">
              <p>Status</p>
            </div>

            <div className="col-3 d-flex justify-content-end">
              <label className="toggle-radio-button">
                <input
                  type="checkbox"
                  checked={formData.status == 1}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      status: e.target.checked ? 1 : 0,
                    })
                  }
                />
                <span className="slider"></span>
              </label>
            </div>
          </div>

          <div className="row pt-3">
            <div className="col-7">
              <button
                type="button"
                className="btn btn-primary w-100"
                onClick={(e) => {
                  if (!formData.name) {
                    sweetAlert("Name is required", "", "warning");
                  } else if (!formData.email) {
                    sweetAlert("Email is required", "", "warning");
                  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(formData.email)) {
                    sweetAlert('Invalid email address', '', 'warning')
                  } else {
                    setUsersData()
                  }
                }}
              >
                Submit
                {/* {userData.email == null ? (
                  <span>Add Email Address</span>
                ) : <span>Change Email Address</span>} */}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
