import { React, useState } from "react";
import TableData_Conditions from "../conditions/tableData_Conditions";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";


import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default function Table({ headings, bodyData }) {




    // Modal

    const [show, setShow] = useState(false);
    const modalClose = () => setShow(false);
    const modalShow = () => setShow(true);

    const [comment, setComment] = useState([]);
    const [date, setDate] = useState([]);


    const showData = async () => {
        modalShow();
    };
    return (
        <>

            <table className="table table-striped table-light">
                <thead>
                    <tr><th className="py-3">#</th>
                        {headings?.map((item, index) => {
                            {
                                return (
                                    <th className="py-3" scope="col" key={index}>
                                        {item.name}
                                    </th>
                                );
                            }
                        })}
                        <th className="py-3">Comments</th>

                    </tr>
                </thead>

                <tbody>

                    {bodyData?.map((item_01, index) => {

                        return (
                            <tr key={index}>

                                <td className="py-3">{index + 1}</td>
                                {headings?.map((item_02, index_02) => {
                                    return (
                                        <td className="py-3" key={index_02} >
                                            <TableData_Conditions
                                                item_01={item_01}
                                                item_02={item_02}
                                            />
                                        </td>
                                    )

                                })}
                                {item_01.comment ?
                                    <td className="py-3"                                >
                                        <button className="btn btn-success" data-toggle="tooltip" data-placement="top" title={(item_01.comment.replace(/<[^>]+>/g, ''))} style={{ cursor: "pointer" }}
                                            onClick={() => (
                                                showData(),
                                                setComment(item_01.comment),
                                                setDate(item_01.date)

                                            )}> View</button>
                                    </td>
                                    :
                                    <td className="py-3">--</td>
                                }

                                {/* {item_01.comment ? <td data-toggle="tooltip" data-placement="top" title={(item_01.comment.replace(/<[^>]+>/g, ''))} style={{ cursor: "pointer" }} className="btn btn-success">
                                    View
                                </td>
                                    : <td>--:--:--</td>} */}


                                {/* < td >
                                    <span className="btn-group">
                                        <button
                                            type="button"
                                            className="btn btn-sm btn-primary"
                                            onClick={() => {
                                            }}
                                        >
                                            Update
                                        </button>
                                        <button
                                            type="button"
                                            className="btn btn-sm btn-danger"
                                            onClick={() => {
                                            }}
                                        >
                                            Delete
                                        </button>
                                    </span>
                                </td> */}
                            </tr>
                        )
                    })}

                </tbody>
            </table>

            {/* Modal */}

            {/* <Modal Modal show={show} onHide={modalClose}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Comment - <span className="text-danger">{date}</span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <span dangerouslySetInnerHTML={{ __html: comment }} />
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn btn-secondary"
                        type="button"
                        onClick={() => {
                            modalClose()
                        }}>Close</Button>
                </Modal.Footer>
            </Modal> */}


            < Modal
                show={show} onHide={modalClose}
                style={{ zIndex: "9999" }}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        Comment - <span className="text-danger">{date}</span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div
                        className="p-3"
                        style={{ overflowY: "scroll", maxHeight: "300px", wordWrap: 'break-word' }}
                        dangerouslySetInnerHTML={{ __html: comment }}>
                    </div>

                    <Modal.Footer>
                        <Button className="btn btn-secondary"
                            type="button"
                            onClick={() => {
                                modalClose()
                            }}>Close</Button>
                    </Modal.Footer>
                </Modal.Body>
            </Modal >
        </>
    )
}