import { request, BASE_URL } from "./apiHandler";

export const Apis_Hit = {
  // Auth Apis

  login_API: (body) => request.post(BASE_URL + "api/auth/login", body),
  loginOut_API: () => request.post(BASE_URL + "api/auth/logout"),
  forgetPassword_API: (body) =>
    request.post(BASE_URL + "api/auth/forget-password", body),
  resetPassword_API: (body) =>
    request.post(BASE_URL + "api/auth/reset-password", body),
  // Attendance Get Apis

  employeeData_API: (body) =>
    request.get(
      BASE_URL +
      "api/attendance/get" +
      "?start_date=" +
      body.start_date +
      "&end_date=" +
      body.end_date
    ),
  getAllUsersAttendanceData_API: (body) =>
    request.get(
      BASE_URL +
      "api/attendance/get-all" +
      "?start_date=" +
      body.start_date +
      "&end_date=" +
      body.end_date
    ),
  getSingleUserDataAdmin_API: (body) =>
    request.get(
      BASE_URL +
      "api/attendance/" +
      body.user_id +
      "/get" +
      "?start_date=" +
      body.start_date +
      "&end_date=" +
      body.end_date
    ),

  // Edit Attendance Apis

  addSingleUserAttendanceAdmin_API: (editEntry) =>
    request.post(BASE_URL + "api/attendance/add", editEntry),
  updateSingleUserAttendanceAdmin_API: (editEntry) =>
    request.post(BASE_URL + "api/attendance/edit", editEntry),
  deleteSingleUserAttendanceAdmin_API: (editEntry) =>
    request.post(BASE_URL + "api/attendance/delete", editEntry),

  // Admin Apis

  getAllUsers_API: (type) =>
    request.get(BASE_URL + "api/users/list" + `?per_page=All${type ? `&type=${type}` : ''}`

    ),
  getUsersData_API: (body) =>
    request.get(BASE_URL + "api/user/get/" + body.user_id),
  setUsersData_API: (body, id) =>
    request.post(BASE_URL + "api/user/set/" + id, body),

  // Comment Apis

  editComment_API: (editEntry) =>
    request.post(BASE_URL + "api/editComment", editEntry),
  deleteComment_API: (editEntry) =>
    request.post(BASE_URL + "api/deleteComment", editEntry),

  // Device Apis

  getLatestAttendance_API: (body) =>
    request.post(BASE_URL + "api/device/attendance-get", body),
  getLatestUsers_API: () => request.get(BASE_URL + "api/device/user-get"),
};
