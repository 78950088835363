import { Navigate } from "react-router-dom";

export const UserRoutes = ({ children }) => {

  const userData = JSON.parse(localStorage.getItem("user"))

  let permission = false;

  if (userData.role === 'Super Admin') {
    permission = true;
  } else if (userData.role === 'Manager') {
    permission = true;
  } else if (userData.role === 'HR') {
    permission = true;
  // } else if (userData.roles === 'Employee') {
    // permission = true;
  } else (
    permission = true
  )

  if (!permission) {
    return <Navigate to="/" replace />
  }

  return children;
}