import React, { useState } from "react";
import { Apis_Hit } from "../apis/apis";
import { DateRangePicker } from "react-date-range";
import { format } from "date-fns";
import * as sweetAlert from 'sweetalert';
import { useNavigate } from "react-router-dom";

import moment from "moment";

import Table from "../components/table";
import Loading from "../components/loading_builtinSoft";

export default function AllUsersDataAdmin() {

  const [employeeData, setEmployeeData] = useState([]);
  const [body, setBody] = useState({});
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState([{ key: "selection" }]);
  const [contentDisplay, setContentDisplay] = useState(false);
  const navigate = useNavigate();

  const getDate = async () => {
    if (!date[0].startDate || !date[0].endDate) {
      body.start_date = moment().startOf('month').format('YYYY-MM-DD 00:00:00');
      body.end_date = moment().endOf('month').format('YYYY-MM-DD 23:59:59');
    } else {
      body.start_date = format(date[0].startDate, "yyyy-MM-dd 00:00:00");
      body.end_date = format(date[0].endDate, "yyyy-MM-dd 23:59:59");
    }
    getAllUsersAttendanceData();

  };

  const getAllUsersAttendanceData = async () => {
    setLoading(true);

    await Apis_Hit.getAllUsersAttendanceData_API(body)
      .then((resp) => {
        setEmployeeData(resp.data.response);
        setLoading(false);
        setContentDisplay(true);
      })
      .catch((errors) => {
        sweetAlert("Error!", "Data Not Found.", "error");
        setLoading(true);
        console.log(errors)
      });
  };

  const dataHeadings = [
    { key: "name", name: "Name" },
    { key: "total_hours", name: "Total Hours" },
  ];
  return (
    <div className="container">
      <div className="row mt-3">
        <div className="offset-1 col">

          <DateRangePicker
            onChange={(item) => setDate([item.selection])}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            months={1}
            ranges={date}
            direction="horizontal"
          />
        </div>
        <div className="offset-1 col">
          <button
            className="btn btn-primary"
            style={{ marginTop: "82px" }}
            onClick={() => getDate()}
          >
            Submit
          </button>
        </div>
      </div>

      {loading ? (
        <Loading />
      ) : (
        <>
          <div className="py-5" style={{ display: (contentDisplay ? "" : "none") }}>
            <h1>Data</h1>

            <table className="table table-striped table-light">
              <thead>
                <tr><th className="py-3">#</th>
                  {dataHeadings?.map((item, index) => {
                    {
                      return (
                        <th className="py-3" scope="col" key={index}>
                          {item.name}
                        </th>
                      );
                    }
                  })}
                  <th className="py-3">Comments</th>

                </tr>
              </thead>

              <tbody>

                {employeeData?.map((item_01, index) => {

                  return (
                    <tr key={index}>

                      <td className="py-3">{index + 1}</td>

                      <td className="py-3 text-primary"

                      >
                        <span style={{ cursor: "pointer" }}
                          onClick={() => {
                            localStorage.setItem('start_date', JSON.stringify(body.start_date));
                            localStorage.setItem('end_date', JSON.stringify(body.end_date));
                            localStorage.setItem('id', JSON.stringify(item_01.id));
                            localStorage.setItem('name', JSON.stringify(item_01.name));

                            navigate("/EmployeeDataAdmin");
                          }}
                        ><u>{item_01.name}</u></span>
                      </td>

                      <td className="py-3">{item_01.total_hours}</td>

                      {!(item_01.total_comments == 0) ? <td className="py-3">{item_01.total_comments}</td> : <td className="py-3">--</td>}
                    </tr>
                  )
                })}

              </tbody>
            </table>

            {/* <Table headings={dataHeadings} bodyData={employeeData} /> */}
          </div>
        </>
      )}
    </div>
  );
}
