import React, { useEffect, useState, useContext } from "react";
import { UserContext } from "../context/userContext";
import { Apis_Hit } from "../apis/apis";
import TableData_Conditions from "../conditions/tableData_Conditions";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import moment from "moment";
import { DateRangePicker } from "react-date-range";
import { format, set, subDays, addDays } from "date-fns";
import * as sweetAlert from "sweetalert";
import * as sweetAlert2 from "sweetalert2";
import CK_EDITOR from "../components/ck_Editor";

import Table from "../components/table";
import Loading from "../components/loading_builtinSoft";

export default function EmployeeDataAdmin() {
  const [allUsersData, setAllUsersData] = useState([]);
  const [employeeData, setEmployeeData] = useState([]);
  const [employeeDataHours, setEmployeeDataHours] = useState([]);
  const [body, setBody] = useState({});
  const [editEntry, setEditEntry] = useState({});
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState([{ key: "selection" }]);
  const { user } = useContext(UserContext);
  const [selectedUser, setSelectedUser] = useState([]);
  const [contentDisplay, setContentDisplay] = useState(false);
  const [modalType, setModalType] = useState();
  const [CK_EDITOR_data, setCK_EDITOR_data] = useState([]);

  const sweetAlertOption = async () => {
    modalClose();

    sweetAlert2
      .fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      })
      .then((result) => {
        if (result.isConfirmed) {
          {
            modalType === "updateEntry"
              ? deleteSingleUserAttendanceAdmin()
              : deleteComment();
          }

          sweetAlert2.fire(
            "Deleted!",
            "Your file has been deleted.",
            "success"
          );
        }
      });
  };

  // Modal

  const [show, setShow] = useState(false);
  const modalClose = () => setShow(false);
  const modalShow = () => setShow(true);

  useEffect(() => {
    getAllUsers();
  }, []);

  const getAllUsers = async () => {
    await Apis_Hit.getAllUsers_API('Active')
      .then((resp) => {
        setAllUsersData(resp.data.response.data);
        onLoadingPage();
      })
      .catch((errors) => console.log(errors));
  };

  const onLoadingPage = async () => {
    if (
      !(
        JSON.parse(localStorage.getItem("start_date")) == null ||
        JSON.parse(localStorage.getItem("end_date")) == null ||
        JSON.parse(localStorage.getItem("id")) == null
      )
    ) {
      body.start_date = JSON.parse(localStorage.getItem("start_date"));
      body.end_date = JSON.parse(localStorage.getItem("end_date"));
      body.user_id = JSON.parse(localStorage.getItem("id"));
      setSelectedUser(JSON.parse(localStorage.getItem("name")));

      getSingleUserDataAdmin();
    }

    localStorage.removeItem("start_date");
    localStorage.removeItem("end_date");
    localStorage.removeItem("id");
    localStorage.removeItem("name");
  };

  const getDate = async () => {
    if (!body.user_id) {
      sweetAlert("**USER** Not Selected!", "Please Provide Data.", "warning");
      setLoading(false);
    } else {
      if (!date[0].startDate || !date[0].endDate) {
        body.start_date = moment()
          .startOf("month")
          .format("YYYY-MM-DD 00:00:00");
        body.end_date = moment().endOf("month").format("YYYY-MM-DD 23:59:59");
      } else {
        body.start_date = format(date[0].startDate, "yyyy-MM-dd 00:00:00");
        body.end_date = format(date[0].endDate, "yyyy-MM-dd 23:59:59");
      }

      allUsersData.map((userData) => {
        if (userData.user_id == body.user_id) {
          setSelectedUser(userData.name);
        }
      });

      getSingleUserDataAdmin();
    }
  };

  const getSingleUserDataAdmin = async () => {
    setEditEntry({});
    setLoading(true);

    await Apis_Hit.getSingleUserDataAdmin_API(body)
      .then((resp) => {
        if (resp.data.status) {
          setEmployeeDataHours(resp.data.response.sum_hours);
          setEmployeeData(resp.data.response.data);
          setLoading(false);
          setContentDisplay(true);
        } else {
          sweetAlert2.fire(
            "No Data Found!",
            "Please Provide Proper Data.",
            "error"
          );
          setLoading(false);
          setContentDisplay(false);
        }
      })
      .catch((errors) => {
        setEmployeeDataHours("No Data Found");
        setLoading(true);
        console.log(errors);
      });
  };

  const showData = async () => {
    modalShow();
  };

  const addSingleUserAttendanceAdmin = async () => {
    modalClose();

    editEntry.user_id = body.user_id;
    editEntry.new_timestamp =
      editEntry.real_date + " " + editEntry.new_timestamp + ":00";

    Object.keys(editEntry).map(function (key) {
      if (!(key == "user_id" || key == "type" || key == "new_timestamp")) {
        delete editEntry[key];
      }
    });

    await Apis_Hit.addSingleUserAttendanceAdmin_API(editEntry)
      .then((resp) => {
        getSingleUserDataAdmin();
      })
      .catch((errors) => console.log(errors));
  };

  const updateSingleUserAttendanceAdmin = async () => {
    modalClose();

    editEntry.user_id = body.user_id;
    editEntry.new_timestamp =
      editEntry.real_date + " " + editEntry.new_timestamp;

    Object.keys(editEntry).map(function (key) {
      if (!(key == "user_id" || key == "new_timestamp" || key == "id")) {
        delete editEntry[key];
      }
    });

    await Apis_Hit.updateSingleUserAttendanceAdmin_API(editEntry)
      .then((resp) => {
        getSingleUserDataAdmin();
      })
      .catch((errors) => console.log(errors));
  };

  const deleteSingleUserAttendanceAdmin = async () => {
    modalClose();

    editEntry.user_id = body.user_id;

    Object.keys(editEntry).map(function (key) {
      if (!(key == "user_id" || key == "id")) {
        delete editEntry[key];
      }
    });

    await Apis_Hit.deleteSingleUserAttendanceAdmin_API(editEntry)
      .then((resp) => {
        getSingleUserDataAdmin();
      })
      .catch((errors) => console.log(errors));
  };

  const deleteComment = async () => {
    modalClose();

    editEntry.user_id = body.user_id;
    editEntry.timestamp = editEntry.real_date;
    editEntry.comment = CK_EDITOR_data;

    Object.keys(editEntry).map(function (key) {
      if (!(key == "user_id" || key == "timestamp" || key == "comment")) {
        delete editEntry[key];
      }
    });

    await Apis_Hit.deleteComment_API(editEntry)
      .then((resp) => {
        getSingleUserDataAdmin();
      })
      .catch((errors) => console.log(errors));
  };

  const editComment = async () => {
    modalClose();

    editEntry.user_id = body.user_id;
    editEntry.timestamp = editEntry.real_date;
    editEntry.comment = CK_EDITOR_data;

    Object.keys(editEntry).map(function (key) {
      if (!(key == "user_id" || key == "timestamp" || key == "comment")) {
        delete editEntry[key];
      }
    });

    await Apis_Hit.editComment_API(editEntry)
      .then((resp) => {
        getSingleUserDataAdmin();
      })
      .catch((errors) => console.log(errors));
  };

  const dataHeadings = [
    { key: "date", name: "Date" },
    { key: "day", name: "Day" },
    { key: "check_in", name: "Check in" },
    { key: "break_in", name: "Break Start" },
    { key: "break_out", name: "Break End" },
    { key: "check_out", name: "Check out" },
    { key: "working_hours", name: "Working Hours" },
    { key: "break_hours", name: "Break Hours" },
    { key: "total_hours", name: "Total Hrs / Day" },
  ];

  return (
    <>
      <div className="container">
        <div className="row mt-3">
          <div className="offset-1 col">
            <DateRangePicker
              onChange={(item) => setDate([item.selection])}
              showSelectionPreview={true}
              moveRangeOnFirstSelection={false}
              months={1}
              ranges={date}
              direction="horizontal"
            />
          </div>
          <div className="offset-1 col">
            <div className="d-flex">
              <select
                className="btn btn-light border border-dark float-left"
                defaultValue={"default"}
                onChange={(e) => (body.user_id = e.target.value)}
                style={{ textAlign: "left" }}
              >
                <option value={"default"} disabled>
                  Please Select Name
                </option>
                {allUsersData?.map((item, index) => (
                  <option key={index} value={item.user_id}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>
            <button className="btn btn-primary mt-5" onClick={() => getDate()}>
              Submit
            </button>
          </div>
        </div>

        {loading ? (
          <>
            <Loading />
          </>
        ) : (
          <span style={{ display: contentDisplay ? "" : "none" }}>
            <div className="py-5">
              <div className="row">
                <div className="col-5">
                  <h3>
                    Name - <span className="text-danger">{selectedUser}</span>
                  </h3>
                </div>
                <div className="offset-3 col-2 pt-3">
                  <h4>Total Hours : </h4>
                </div>
                <div className="col-2 pt-3">
                  <h4 className="text-danger">{employeeDataHours}</h4>
                </div>
              </div>
            </div>
            <table className="table table-striped table-light">
              <thead>
                <tr>
                  <th className="py-3">#</th>
                  {dataHeadings?.map((item, index) => {
                    {
                      return (
                        <th className="py-3" scope="col" key={index}>
                          {item.name}
                        </th>
                      );
                    }
                  })}
                  <th className="py-3">Comments</th>
                </tr>
              </thead>

              <tbody>
                {employeeData?.map((item_01, index) => {
                  return (
                    <tr key={index}>
                      <td className="py-3">{index + 1}</td>
                      {dataHeadings?.map((item_02, index_02) => {
                        return (
                          <td className="py-3" key={index_02}>
                            <TableData_Conditions
                              item_01={item_01}
                              item_02={item_02}
                              showData={showData}
                              editEntry={editEntry}
                              setModalType={setModalType}
                              dataType={"admin"}
                            />
                          </td>
                        );
                      })}

                      <td>
                        {item_01.comment === null ? (
                          <a
                            style={{ textDecoration: "none" }}
                            className="btn btn-link"
                            onClick={() => (
                              setModalType("addComment"),
                              (editEntry.real_date = item_01.date),
                              (editEntry.editHead = item_01.date),
                              (editEntry.comment = item_01.comment),
                              showData()
                            )}
                          >
                            Add
                          </a>
                        ) : (
                          <>
                            <span
                              data-toggle="tooltip"
                              data-placement="top"
                              title={item_01.comment.replace(/<[^>]+>/g, "")}
                              style={{ cursor: "pointer" }}
                              className="btn btn-success"
                              onClick={() => (
                                setModalType("editComment"),
                                showData(),
                                (editEntry.real_date = item_01.date),
                                (editEntry.editHead = item_01.date),
                                (editEntry.comment = item_01.comment)
                              )}
                            >
                              View
                            </span>
                          </>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            {/* <Table headings={dataHeadings} bodyData={employeeData} /> */}
          </span>
        )}
      </div>

      {/* Modal */}

      <Modal
        show={show}
        onHide={modalClose}
        style={{ zIndex: "9999" }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {modalType === "addEntry" && "Add New Data"}
            {modalType === "updateEntry" && "Update Data"}
            {modalType === "addComment" && "Add Comment"}
            {modalType === "editComment" && "Edit Comment"}

            {" - "}

            <span className="text-danger">{editEntry.editHead}</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            {modalType === "updateEntry" && (
              <>
                {"Original Data - "}
                <span className="text-danger">
                  {moment(editEntry.real_time, ["hh:mm:ss A"]).format(
                    "hh:mm:ss A"
                  )}
                </span>
              </>
            )}
          </p>
          {modalType === "updateEntry" && <hr />}

          {modalType === "editComment" && (
            <CK_EDITOR
              setCK_EDITOR_data={setCK_EDITOR_data}
              comment={editEntry.comment}
            />
          )}
          {modalType === "addComment" && (
            <CK_EDITOR
              setCK_EDITOR_data={setCK_EDITOR_data}
              comment={editEntry.comment}
            />
          )}

          <form>
            <div className="row">
              <div className="col-3">
                <label className="form-label">
                  {modalType === "addEntry" && "Add Data"}
                  {modalType === "updateEntry" && "Edit Data"}
                </label>
              </div>

              <div>
                {modalType === "addEntry" && (
                  <input
                    type="time"
                    className="form-control"
                    onChange={(e) => (editEntry.new_timestamp = e.target.value)}
                    required
                  />
                )}

                {modalType === "updateEntry" && (
                  <input
                    type="time"
                    className="form-control"
                    defaultValue={editEntry.real_time}
                    onChange={(e) => (editEntry.new_timestamp = e.target.value)}
                    required
                  />
                )}
              </div>
            </div>
            <Modal.Footer className="mt-3">
              {modalType === "updateEntry" && (
                <Button
                  className="btn btn-danger"
                  variant="danger"
                  type="button"
                  onClick={() => {
                    sweetAlertOption();
                  }}
                >
                  Delete
                </Button>
              )}

              {modalType === "editComment" && (
                <Button
                  className="btn btn-danger"
                  variant="danger"
                  type="button"
                  onClick={() => {
                    sweetAlertOption();
                  }}
                >
                  Delete
                </Button>
              )}

              <Button
                variant="primary"
                type="button"
                onClick={() => {
                  {
                    modalType === "addEntry" && addSingleUserAttendanceAdmin();
                  }
                  {
                    modalType === "updateEntry" &&
                      updateSingleUserAttendanceAdmin();
                  }
                  {
                    modalType === "editComment" && editComment();
                  }
                  {
                    modalType === "addComment" && editComment();
                  }
                }}
              >
                {modalType === "addEntry" && "Add Data"}
                {modalType === "updateEntry" && "Update Data"}
                {modalType === "addComment" && "Add Comment"}
                {modalType === "editComment" && "Edit Comment"}
              </Button>
            </Modal.Footer>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}
