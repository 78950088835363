import React, { useState } from "react";
import { Apis_Hit } from "../../apis/apis";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import * as sweetAlert from "sweetalert";
import Loading from "../../components/loading_builtinSoft";

export default function ForgetPasswordForm() {
  const [body, setBody] = useState({});
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const forgetPassword = async () => {
    setLoading(true);
    await Apis_Hit.forgetPassword_API(body)
      .then((resp) => {
        sweetAlert("Success!", "Please Check Your Mail Box.", "success");
        navigate("/", { replace: true });
      })
      .catch((errors) => {
        sweetAlert("Not Sent!", "Unauthorized email address.", "error");
        setLoading(false);
      });
  };

  return (
    <section className="vh-100">
      <div className="container-fluid h-custom">
        <div className="row d-flex justify-content-center align-items-center h-100">
          <div className="col-md-8 col-lg-5 col-xl-4">
            {loading ? (
              <Loading />
            ) : (
              <img
                src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/draw2.webp"
                className="img-fluid"
                alt="Sample image"
              />
            )}
          </div>
          <div className="col-md-8 col-lg-6 col-xl-4 offset-xl-1">
            <form>
              <div className="form-outline mb-4">
                <label className="form-label" htmlFor="form3Example3">
                  Email Address
                </label>
                <input
                  type="email"
                  id="form3Example3"
                  className="form-control form-control-lg"
                  placeholder="Enter a valid email address"
                  onChange={(e) => (body.email = e.target.value)}
                />
              </div>

              <div className="text-center text-lg-start mt-4 pt-2">
                <button
                  type="button"
                  className="btn btn-primary btn-lg "
                  style={{ marginLeft: "25%", width: "50%" }}
                  onClick={() => forgetPassword()}
                >
                  Send Mail
                </button>
              </div>
              <div className="d-flex justify-content-between align-items-center mt-5">
                <div className="form-check mb-0"></div>
                <Link className="nav-link text-primary text-body" to={"/"}>
                  <u>Back to Login</u>
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}
