import './App.css';
import Routing from './content/routes/routing'
import { UserContext } from "./content/context/userContext";
import React, { useState } from "react";
import './content/mergeAllCss'
import { getToken, getUser } from './content/storage/storage'

function App() {
  const [user, setUser] = useState(getUser())
  const [token, setToken] = useState(getToken())


  return (
      <UserContext.Provider value={{ user, setUser, token, setToken }}>
        <Routing />
      </UserContext.Provider>
  );
}

export default App;
