import gifLoader from './builtinSoft_loader.gif'

export default function loading_builtinSoft() {
    return (
        <>
            <div className='container text-center mt-5' >
                <img style={{width:"300px"}} src={gifLoader} alt="logo" />
            </div>
        </>
    );
}