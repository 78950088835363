import axios from "axios";
import * as local from '../storage/storage'

export const BASE_URL = process.env.REACT_APP_API_URL;

export const request = axios.create();

request.interceptors.request.use(function (config) {
    // Do something before request is sent
    let token = local.getToken();

    // let token = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOi8vMTI3LjAuMC4xOjgwMDAvYXBpL2F1dGgvYWRtaW4vc2lnbmluIiwiaWF0IjoxNjczOTU1MjE5LCJleHAiOjE2NzQ1NjAwMTksIm5iZiI6MTY3Mzk1NTIxOSwianRpIjoiWERaVGpiaE84ZHhGTjA0dSIsInN1YiI6IjI0IiwicHJ2IjoiMjNiZDVjODk0OWY2MDBhZGIzOWU3MDFjNDAwODcyZGI3YTU5NzZmNyJ9.YQrWZ5e5JOSCBu5I1SEjAG2sA-yDxhG_ezIABY7cZUs";
    config.headers["Accept"] = "application/json";
    config.headers["Authorization"] = token ? "Bearer " + token : '';
    return config;
});