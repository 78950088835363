import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import { TokenCheck } from "./protectedRoutes/tokenCheck";
import { UserRoutes } from "./protectedRoutes/userRoutes";
import { AdminRoutes } from "./protectedRoutes/adminRoutes";

import Login from "../pages/auth/login";
import EmployeeData from "../pages/employeeData";
import EmployeeDataAdmin from "../pages/employeeDataAdmin";
import AllUsersDataAdmin from "../pages/allUsersDataAdmin";
import NavBar from "../layout/navBar";
import UpdateUsers from "../pages/users/updateUsers";
import Date from "../components/date";
import ForgetPasswordForm from "../pages/auth/forgetPassword";

import OnClose from "../components/onClose";
import ResetPassword from "../pages/auth/resetPassword";

export default function Routing() {
  return (
    <BrowserRouter>
      <OnClose />

      <NavBar />

      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/forgetPassword-set" element={<ResetPassword />} />
        <Route path="*" element={<Login />} />

        <Route path="/forgetPassword" element={<ForgetPasswordForm />} />

        <Route
          path="/EmployeeData"
          element={
            <TokenCheck>
              <UserRoutes>
                <EmployeeData />
              </UserRoutes>
            </TokenCheck>
          }
        ></Route>

        <Route
          path="/EmployeeDataAdmin"
          element={
            <TokenCheck>
              <AdminRoutes>
                <EmployeeDataAdmin />
              </AdminRoutes>
            </TokenCheck>
          }
        ></Route>

        <Route
          path="/AllUsersDataAdmin"
          element={
            <TokenCheck>
              <AdminRoutes>
                <AllUsersDataAdmin />
              </AdminRoutes>
            </TokenCheck>
          }
        ></Route>

        <Route
          path="/UpdateUsers"
          element={
            <TokenCheck>
              <AdminRoutes>
                <UpdateUsers />
              </AdminRoutes>
            </TokenCheck>
          }
        ></Route>

        <Route
          path="/Date"
          element={
            <TokenCheck>
              <AdminRoutes>
                <Date />
              </AdminRoutes>
            </TokenCheck>
          }
        ></Route>
      </Routes>
    </BrowserRouter>
  );
}
