import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

import { DateRangePicker } from "react-date-range";
import { addDays } from "date-fns";
import { useState } from "react";

export default function Date() {
  const [state, setState] = useState([
    {
      // startDate: new Date(),
      // endDate: addDays(new Date(), 7),
      key: "selection",
    },
  ]);

  const getDate = async () => {
    // console.log("ok");
    console.log(state);
  };

  return (
    <div>
      {" "}
      <DateRangePicker
        onChange={(item) => setState([item.selection])}
        showSelectionPreview={true}
        moveRangeOnFirstSelection={false}
        months={2}
        ranges={state}
        direction="horizontal"
      />

      <button onClick={()=>getDate()}>Submit</button>
    </div>
  );
}
