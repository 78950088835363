import React from "react";
import { FaEdit } from "react-icons/fa";
import moment from "moment";

export default function TableData_Conditions({
  item_01,
  item_02,
  editEntry,
  showData,
  setModalType,
  dataType,
}) {

  var data = [];
  data["icon"] = null;
  data["value"] = item_01[item_02.key];
  data["color"] = null;
  data["edited"] = null;
  data["added"] = null;
  data["tooltip_newAdded"] = "";
  data["tooltip_edited"] = "";
  data["tooltip_deleted"] = "";
  data["day"] = moment(item_01['date']).format('dddd');

  // Color Decoration on VALID DATA

  if (item_02.name === "Check in" && item_01[item_02.key] >= "09:30:59") {
    data["color"] = "red";
  } else if (item_02.name === "Check out" && item_01[item_02.key] >= "18:30:59") {
    data["color"] = "red";
  } else if (item_02.name === "Total Hrs / Day" && item_01[item_02.key] >= "8.2") {
    data["color"] = "red";
  } else if (item_02.name === "Total Hrs / Day" && item_01[item_02.key] <= "7.8") {
    data["color"] = "red";
  } else if (item_02.name === "Day" && (item_01[item_02.key] == "Saturday" || item_01[item_02.key] == "Sunday")) {
    data["color"] = "red";
  } else if (item_02.name === "Date" && (data["day"] == "Saturday" || data["day"] == "Sunday")) {
    data["color"] = "red";
  }

  // Check New Added Data

  if (item_02.name === "Check in" && !(item_01["check_in_newAdded"] == null)) {
    data["edited"] = "underline";
    data["tooltip_newAdded"] = "Added Time: " + item_01["check_in_newAdded"];
  } else if (item_02.name === "Break Start" && !(item_01["break_in_newAdded"] == null)) {
    data["edited"] = "underline";
    data["tooltip_newAdded"] = "Added Time: " + item_01["break_in_newAdded"];
  } else if (item_02.name === "Break End" && !(item_01["break_out_newAdded"] == null)) {
    data["edited"] = "underline";
    data["tooltip_newAdded"] = "Added Time: " + item_01["break_out_newAdded"];
  } else if (item_02.name === "Check out" && !(item_01["check_out_newAdded"] == null)) {
    data["edited"] = "underline";
    data["tooltip_newAdded"] = "Added Time: " + item_01["check_out_newAdded"];
  }

  // Check Edited Data

  if (item_02.name === "Check in" && !(item_01["check_in_edited"] == null)) {
    data["tooltip_edited"] = "Previous Time: " + item_01["check_in_edited"];
    data["edited"] = "underline";
  } else if (item_02.name === "Break Start" && !(item_01["break_in_edited"] == null)) {
    data["tooltip_edited"] = "Previous Time: " + item_01["break_in_edited"];
    data["edited"] = "underline";
  } else if (item_02.name === "Break End" && !(item_01["break_out_edited"] == null)) {
    data["tooltip_edited"] = "Previous Time: " + item_01["break_out_edited"];
    data["edited"] = "underline";
  } else if (item_02.name === "Check out" && !(item_01["check_out_edited"] == null)) {
    data["tooltip_edited"] = "Previous Time: " + item_01["check_out_edited"];
    data["edited"] = "underline";
  }

  // Check Deleted Data

  if (item_02.name === "Check in" && !(item_01["check_in_deleted"] == null)) {
    data["tooltip_deleted"] = "Last Deleted: " + item_01["check_in_deleted"];
    data["edited"] = "underline";
  } else if (item_02.name === "Break Start" && !(item_01["break_in_deleted"] == null)) {
    data["tooltip_deleted"] = "Last Deleted: " + item_01["break_in_deleted"];
    data["edited"] = "underline";
  } else if (item_02.name === "Break End" && !(item_01["break_out_deleted"] == null)) {
    data["tooltip_deleted"] = "Last Deleted: " + item_01["break_out_deleted"];
    data["edited"] = "underline";
  } else if (item_02.name === "Check out" && !(item_01["check_out_deleted"] == null)) {
    data["tooltip_deleted"] = "Last Deleted: " + item_01["check_out_deleted"];
    data["edited"] = "underline";
  }

  // Changing DATA VALUE as required

  if (item_01[item_02.key] == "Holiday" && (item_02.name === "Check in" || item_02.name === "Break Start" || item_02.name === "Break End" || item_02.name === "Check out")) {
    data["value"] = "HOLIDAY";
    data["color"] = "grey";
  } else if (dataType == "admin" && !(item_01[item_02.key] == null) && (item_02.name === "Check in" || item_02.name === "Break Start" || item_02.name === "Break End" || item_02.name === "Check out")) {
    data["icon"] = <FaEdit />;
  } else if (dataType == "admin" && (item_01[item_02.key] == null) && (item_02.name === "Check in" || item_02.name === "Break Start" || item_02.name === "Break End" || item_02.name === "Check out")) {
    data["value"] = "addButton";
  } else if (item_01[item_02.key] == null && (item_02.name === "Check in" || item_02.name === "Break Start" || item_02.name === "Break End" || item_02.name === "Check out")) {
    data["value"] = "--:--:--";
  } else if (item_01[item_02.key] == 0 && (item_02.name === "Working Hours" || item_02.name === "Break Hours" || item_02.name === "Total Hrs / Day")) {
    data["value"] = "--";
    data["color"] = "black";
  }

  return (
    <span
      style={{ color: data['color'], textDecoration: data["edited"] }}
      data-toggle="tooltip"
      data-placement="top"
      title={(data["tooltip_newAdded"] && data["tooltip_newAdded"]) + (data["tooltip_edited"] && ("\n" + data["tooltip_edited"])) + (data["tooltip_deleted"] && ("\n" + data["tooltip_deleted"]))}
    >
      <sub>{data["added"]}</sub>

      {(data["value"] == 'addButton') ?
        <span
          style={{ textDecoration: data['edited'] , cursor:"pointer"}}
          className="ms-3 link-primary"
          onClick={() => (
            setModalType('addEntry'),
            (editEntry.editHead = item_02.name),
            (editEntry.type = item_02.key),
            (editEntry.real_date = item_01["date"]),
            showData()
          )}
        >
          Add
        </span>
        : data["value"]
      }

      <sup
        className="edit_button"
        onClick={() => (
          showData((editEntry.id = item_01[item_02.key + "_id"])),
          (editEntry.state = item_02.key),
          (editEntry.editHead = item_02.name),
          (editEntry.real_date = item_01["date"]),
          (editEntry.real_time = item_01[item_02.key]),
          setModalType('updateEntry')
        )}
      >
        {data["icon"]}
      </sup>
    </span>
  );
}
