import React, { useContext, useState, useEffect } from "react";
import { UserContext } from "../../context/userContext";
import { Apis_Hit } from "../../apis/apis";
import { useNavigate } from "react-router-dom";
import { storeToken, storeUser } from "../../storage/storage";
import { Link } from "react-router-dom";
import * as sweetAlert from "sweetalert";
import Loading from "../../components/loading_builtinSoft";

export default function Login() {
  const navigate = useNavigate();
  const [body, setBody] = useState({});
  const { setUser, setToken } = useContext(UserContext);
  const [loading, setLoading] = useState(false);

  const login = async (event) => {
    event.preventDefault(); // 👈️ prevent page refresh on submit form

    setLoading(true);
    await Apis_Hit.login_API(body)
      .then((resp) => {
        storeToken(resp.data.response.token);
        setToken(resp.data.response.token);
        storeUser(resp.data.response.data);
        setUser(resp.data.response.data);
        if (resp?.data?.response?.data?.role === "Super Admin") {
          navigate("/EmployeeDataAdmin");
        } else {
          navigate("/EmployeeData");
        }
      })
      .catch((errors) => {
        sweetAlert("Error!", "Wrong Credential.", "error");
        setLoading(false);
      });
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    navigate("/", { replace: true });
  };

  useEffect(() => {
    handleLogout();
  }, []);

  return (
    <section className="vh-100">
      <div className="container-fluid h-custom">
        <div className="row d-flex justify-content-center align-items-center h-100">
          <div className="col-md-8 col-lg-5 col-xl-4">
            {loading ? (
              <Loading />
            ) : (
              <img
                src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/draw2.webp"
                className="img-fluid"
                alt="Sample image"
              />
            )}
          </div>

          {/* <div className="col-md-8 col-lg-5 col-xl-4">

            <img
              src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/draw2.webp"
              className="img-fluid"
              alt="Sample image"
            />
          </div>

          <div className="col-md-1 col-lg-1 col-xl-1">

            {loading && <Loading />
            }
          </div> */}

          <div className="col-md-8 col-lg-6 col-xl-4 offset-xl-1">
            <form onSubmit={login}>
              <div className="form-outline mb-4">
                <label className="form-label" htmlFor="form3Example3">
                  Email Address
                </label>
                <input
                  type="email"
                  id="form3Example3"
                  className="form-control form-control-lg"
                  placeholder="Enter a valid email address"
                  onChange={(e) => (body.email = e.target.value)}
                />
              </div>

              <div className="form-outline mb-3">
                <label className="form-label" htmlFor="form3Example4">
                  Password
                </label>
                <input
                  type="password"
                  id="form3Example4"
                  className="form-control form-control-lg"
                  placeholder="Enter password"
                  onChange={(e) => (body.password = e.target.value)}
                />
              </div>

              <div className="d-flex justify-content-between align-items-center">
                <div className="form-check mb-0"></div>
                <Link
                  className="nav-link text-primary text-body"
                  to={"/forgetPassword"}
                >
                  <u>Forgot Password</u>
                </Link>
              </div>

              <div className="text-center text-lg-start mt-4 pt-2">
                <button
                  type="submit"
                  className="btn btn-primary btn-lg "
                  style={{ marginLeft: "5%", width: "90%" }}
                // onClick={() => login()}
                >
                  Login
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      {/* <div className="d-flex flex-column flex-md-row text-center text-md-start justify-content-between py-4 px-4 px-xl-5 bg-primary">
        <div className="text-white mb-3 mb-md-0">
          Copyright © 2020. All rights reserved.
        </div>
      </div> */}
    </section>
  );
}
